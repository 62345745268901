import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import 'amfe-flexible';
import axios from 'axios'
import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// 测试
// axios.defaults.baseURL = 'https://dev.order.hwgwb.com/api'
// 正式
// axios.defaults.baseURL = '/api'

if(process.env.NODE_ENV == 'production'){
  // 生产
  axios.defaults.baseURL = '/api'
}else{
  axios.defaults.baseURL = 'https://dev.order.hwgwb.com/api'
}

Vue.prototype.$axios = axios

// 引入消息提示组件
import Toasted from 'vue-toasted';
Vue.use(Toasted)

Vue.config.productionTip = false

// import './style/index.css'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
